import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertV2 as Alert, ConfirmModalV1, EAlertV2Type } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import { save } from '../SaveCampaignButton';
import type { IUseActionsEffectProps } from './useActionsEffect';
import useActionsEffect from './useActionsEffect';

interface IProps
  extends Omit<IUseActionsEffectProps, 'isPublishModalOpen' | 'togglePublishModalOpen'> {}

function Actions(props: Readonly<IProps>): JSX.Element | null {
  const account = useAccount();
  const [isPublishModalOpen, togglePublishModalOpen] = useToggle(false);
  const { t } = useTranslation();

  // Set wizard actions in action bar
  useActionsEffect({ ...props, isPublishModalOpen, togglePublishModalOpen });

  if (!isPublishModalOpen) {
    return null;
  }

  async function handlePublish(): Promise<void> {
    const { campaign, childModels, grandchildModels } = props;
    await save({
      campaign,
      childModels,
      grandchildModels,
      shouldChangeState: true,
      t,
      accountId: account.id,
    });
    togglePublishModalOpen();
  }

  return (
    <ConfirmModalV1
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Publish')}
      confirmButtonType={'success'}
      onClose={togglePublishModalOpen}
      onConfirm={handlePublish}
      t={t}
      title={t('Publish campaign')}
    >
      <Alert
        description={t(
          'After publishing this drip campaign, you won’t be able to edit anything other than its goals.',
        )}
        title={t('Heads up!')}
        type={EAlertV2Type.warning}
      />
    </ConfirmModalV1>
  );
}

export default observer(Actions);
