import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign, DripStepCampaign, IDripCampaignStepSpec } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

import { validateAutomation, validateInitialEnrollment } from './DripCampaignEdit.utils';

type TValidityMap = Array<[number, boolean]>;

export interface IUseDripStepValidationResponse {
  errors: string[];
  firstInvalidStepIndex: number | null;
  invalidSteps: number[];
}

export interface IUseDripStepValidationProps {
  campaign: DripCampaign;
  steps: IDripCampaignStepSpec[];
}

/** Returns the validation errors associated with all steps in the drip campaign builder */
function useDripStepValidation({
  campaign,
  steps,
}: Readonly<IUseDripStepValidationProps>): IUseDripStepValidationResponse {
  const { Campaigns } = useContext(StoresContext);
  const { t } = useTranslation();

  const errors = useMemo(() => {
    const ephemeralCampaigns = steps.map((step) => {
      const dripCampaignStep = {
        ...step,
        parent_id: campaign.id,
        _cls: CampaignClass.DripStep,
      };
      return Campaigns.create(dripCampaignStep) as DripStepCampaign;
    });

    const validityMap: TValidityMap = [];

    const validationErrors = ephemeralCampaigns
      .map((ephemeralCampaign, index) => {
        const currentStep = index + 1;
        const message = t('Step {{ currentStep }} must be completed', {
          currentStep,
        });

        if (index === 0) {
          const initialEnrollmentErrors = flattenErrors(
            validateInitialEnrollment(ephemeralCampaign),
          );
          validityMap.push([index, !!initialEnrollmentErrors.length]);
          return initialEnrollmentErrors.length ? message : undefined;
        } else {
          const automationErrors = flattenErrors(validateAutomation(ephemeralCampaign));
          validityMap.push([index, !!automationErrors.length]);
          return automationErrors.length ? message : undefined;
        }
      })
      // We're filtering out the undefineds here so it will always be an array of strings
      .filter((error) => error !== undefined) as string[];

    const invalidSteps = validityMap.filter(([, value]) => value).map(([key]) => key);
    const firstInvalidStepIndex = invalidSteps?.[0] ?? null;

    return { validationErrors, firstInvalidStepIndex, invalidSteps };
  }, [Campaigns, campaign.id, steps, t]);

  return {
    errors: errors.validationErrors,
    firstInvalidStepIndex: errors.firstInvalidStepIndex,
    invalidSteps: errors.invalidSteps,
  };
}

export default useDripStepValidation;
