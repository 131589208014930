import { useContext, useMemo } from 'react';

import type { DripStepCampaign, IDripCampaignStepSpec, Template } from '@feathr/blackbox';
import { CampaignClass, dripExcludedAttributes } from '@feathr/blackbox';
import { StoresContext } from '@feathr/extender/state';

interface IEphemeralCampaignResponse {
  campaign: DripStepCampaign;
  handleApply: () => Promise<void>;
  handleCancel: () => Promise<void>;
  handleSync: () => Promise<void>;
  template: Template | null;
}

interface IUseEphemeralCampaignProps {
  dripCampaignId: string;
  onApply?: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  onCancel?: () => void;
  onSync?: (updatedStep: IDripCampaignStepSpec, template: Template | null) => Promise<void>;
  step: IDripCampaignStepSpec;
}

function useEphemeralCampaign({
  dripCampaignId,
  onApply,
  onCancel,
  onSync,
  step,
}: Readonly<IUseEphemeralCampaignProps>): IEphemeralCampaignResponse {
  const { Campaigns, Templates } = useContext(StoresContext);

  const { step: stepAttributes = step } = step;
  const campaign = useMemo(() => {
    const dripCampaignStep = {
      ...stepAttributes,
      parent_id: dripCampaignId,
      _cls: CampaignClass.DripStep,
    };
    return Campaigns.create(dripCampaignStep) as DripStepCampaign;
  }, []);

  const templateId = campaign.get('template_id');
  const template = templateId ? Templates.get(templateId) : null;

  function getUpdatedStep(): IDripCampaignStepSpec {
    const updatedCampaignAttributes = campaign.toJS();
    for (const key of dripExcludedAttributes) {
      delete updatedCampaignAttributes[key];
    }
    return updatedCampaignAttributes as IDripCampaignStepSpec;
  }

  async function handleApply(): Promise<void> {
    if (onApply) {
      const updatedStep = getUpdatedStep();
      await onApply(updatedStep);
    }
  }

  async function handleSync(): Promise<void> {
    if (onSync) {
      const updatedStep = getUpdatedStep();
      await onSync(updatedStep, template);
    }
  }

  async function handleCancel(): Promise<void> {
    if (onSync) {
      await onSync(campaign.initialAttributes, template);
    }

    if (onCancel) {
      onCancel();
    }
  }

  return {
    campaign,
    handleApply,
    handleCancel,
    handleSync,
    template,
  };
}

export default useEphemeralCampaign;
