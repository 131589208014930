import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import React from 'react';

import type { IPageHeaderProps } from '@feathr/components';
import { PageHeader, Spinner } from '@feathr/components';
import GlobalNotifications from '@feathr/extender/components/GlobalNotifications';
import UserErrorBoundary from '@feathr/extender/components/UserErrorBoundary';

import Breadcrumbs from './Breadcrumbs';
import PageActionBar from './PageActionBar';
import PrimaryNav from './PrimaryNav';
import RouteAlert from './RouteAlert';
import usePageTitle from './usePageTitle';

import * as styles from './Page.css';

export interface IPageProps extends Omit<IPageHeaderProps, 'children'> {
  alerts?: ReactNode;
  children: ReactNode;
  headerChildren?: ReactNode;
  className?: string;
  /** CSS className for main page content */
  contentClassName?: string;
  nav?: ReactNode;
  /** Falls back to title if not set */
  pageTitle?: string;
  showHeader?: boolean;
  /**
   * Width of legacy main page content
   * @deprecated Child components should handle width
   */
  width?: 'narrow' | 'wide' | 'full';
}

function Page({
  actions,
  alerts,
  children,
  className,
  contentClassName,
  description,
  headerChildren,
  loading = false,
  nav,
  pageTitle,
  prefix,
  secondaryText,
  showHeader = true,
  width,
  tabs = [],
  title,
}: Readonly<IPageProps>): JSX.Element {
  usePageTitle({ pageTitle, title });

  const alertItems = React.Children.map(alerts as any, (alert: JSX.Element) => {
    // Live site uses type.displayName, jest uses type.name.
    if (alert.type.displayName === 'Alert' || alert.type.name === 'Alert') {
      return React.cloneElement(alert, {
        className: classNames(styles.pageAlerts, alert.props.className),
      });
    }
    return alert;
  });

  const widthClass = width ? styles[width] : undefined;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.content} id={'legacyScrollElement'}>
        <UserErrorBoundary>
          {showHeader && (
            <PageHeader
              actions={actions}
              BreadcrumbsComponent={
                <Breadcrumbs className={styles.breadcrumbs} includeCurrentPage={false} />
              }
              description={description}
              loading={loading}
              prefix={prefix}
              RouteAlertComponent={
                <>
                  <RouteAlert className={styles.pageAlerts} />
                  {alertItems}
                </>
              }
              secondaryText={secondaryText}
              tabs={tabs}
              title={title}
            >
              {headerChildren}
            </PageHeader>
          )}
          <main className={classNames(styles.main, widthClass, contentClassName)}>
            {width ? (
              loading ? (
                <div>
                  <Spinner className={styles.spinner} />
                </div>
              ) : (
                // Add wrapper div to ensure maximum compatibility for aligning page content
                <div>{children}</div>
              )
            ) : loading ? (
              <Spinner className={styles.spinner} />
            ) : (
              children
            )}
          </main>
        </UserErrorBoundary>
      </div>
      <PageActionBar />
      <PrimaryNav className={styles.sidebar} secondaryNav={nav} />
      <header className={styles.globalNotifications}>
        <GlobalNotifications />
      </header>
    </div>
  );
}

export default Page;
