import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AutoPinpointEmailBaseCampaign, TPinpointSubtype } from '@feathr/blackbox';
import type { IRadioOption } from '@feathr/components';

type TUseTriggerTypeRadios = {
  handleSubtypeChange: (newValue?: string) => void;
  repeat: 'first' | 'every';
  setRepeat: Dispatch<SetStateAction<'first' | 'every'>>;
  subtype: TPinpointSubtype;
  triggerTypeOptions: Array<IRadioOption<TPinpointSubtype>>;
};

function useTriggerTypeRadios(campaign: AutoPinpointEmailBaseCampaign): TUseTriggerTypeRadios {
  const [subtype, setSubtype] = useState<TPinpointSubtype>(campaign.get('subtype') ?? 'activity');
  const [repeat, setRepeat] = useState<'first' | 'every'>(
    campaign.get('repeat', false) ? 'every' : 'first',
  );

  const { t } = useTranslation();

  useEffect(() => {
    campaign.set({ repeat: repeat === 'every', subtype: subtype });
  }, []);

  return useMemo(() => {
    const defaults = campaign.getDefaults();
    const triggerTypeOptions: Array<IRadioOption<TPinpointSubtype>> = [
      {
        id: 'activity',
        name: t('Activity happened'),
      },
      {
        id: 'update',
        name: t('Field changed'),
      },
      {
        id: 'time',
        name: t('Date triggered'),
      },
    ];

    function handleSubtypeChange(newValue?: string): void {
      if (!newValue) {
        return;
      }

      if (newValue === 'time') {
        setRepeat('first');
        /*
         * Even though the backend will ignore delay_value and cooldown_value when subtype is time,
         * reset them to zero so the data is more representative of the UI state.
         */
        campaign.set({
          subtype: newValue,
          repeat: false,
          delay_value: 0,
          cooldown_value: 0,
          mode: 'match_any',
        });
      } else {
        campaign.set({
          delay_value: defaults.delay_value,
          delay_unit: defaults.delay_unit,
          mode: defaults.mode,
          send_schedule: defaults.send_schedule,
          subtype: newValue as TPinpointSubtype,
          time_to_send: undefined,
        });
      }
      setSubtype(newValue as TPinpointSubtype);

      // Clear the existing trigger if trigger type is modified
      campaign.set({ actions: [] });
    }

    return {
      handleSubtypeChange,
      repeat,
      setRepeat,
      subtype,
      triggerTypeOptions,
    };
  }, [campaign, repeat, setRepeat, subtype, setSubtype, t]);
}

export default useTriggerTypeRadios;
