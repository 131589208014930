import type { IObservableArray } from 'mobx';
import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type {
  IBaseAttributes,
  IListOptions,
  IListParams,
  ListResponse,
  TConstraints,
} from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { IAddress } from '../address';
import type { IBreadcrumb } from '../breadcrumbs';
import type { IPredicate, IPredicateGroup } from '../segments';

export enum EBrowsers {
  chrome = 'chrome',
  edge = 'edge',
  firefox = 'firefox',
  msie = 'internet Explorer',
  opera = 'opera',
  safari = 'safari',
  yahoo = 'yahoo',
}

export enum EPlatforms {
  windows = 'windows',
  macos = 'osx',
  iphone = 'iphone',
  ipad = 'ipad',
  android = 'android',
  chromeos = 'chromeos',
}

// TODO: Refactor occurrence + value interfaces into a singular interface
export interface ICompanyResponseObject {
  // The number of times this value appears on people.
  occurrence: number;
  value: string;
}

export interface IGeoIP {
  lat: number;
  lng: number;
  postal_code: string;
  timezone: string;
  locality: string;
  continent_code: string;
  country_code: string;
  country_name: string;
  administrative_area_name: string;
  administrative_area_code: string;
  sub_administrative_area_name: string;
  sub_administrative_area_code: string;
}

interface IPlaceholder {
  name?: string;
  color_name?: string;
  color_hex?: string;
  bird?: string;
}

export type TPersonCustomDataValue = string | string[] | number | boolean | undefined;

export interface IPersonCustomData {
  [key: string]: TPersonCustomDataValue;
}

export interface IPerson extends IBaseAttributes {
  // TODO: Once data in DB includes country_name, it should be a required field.
  address: Omit<IAddress, 'country_code'>;
  associated: string[];
  breadcrumbs: IObservableArray<IBreadcrumb>;
  cookies: string[];
  custom: IPersonCustomData;
  custom_data: IPersonCustomData;
  companies: string[];
  conv_count: number;
  date_created: string;
  date_last_heard_from: string;
  date_last_seen: string;
  date_last_session_start: string;
  // Primary email address
  email: string;
  // Additional email addresses
  emails: string[];
  external_id: string;
  first_name?: string;
  last_brow?: EBrowsers | string;
  last_pform?: EPlatforms | string;
  last_name?: string;
  name: string;
  seen_count: number;
  session_count: number;
  geoip: IGeoIP;
  occupation: string;
  source: string;
  phone: string;
  placeholder: IPlaceholder;
  pp_opt_outs?: {
    all: boolean;
    campaigns?: string[];
    events?: string[];
  };
  tag_ids: string[];
}

export interface ICreatePersonResponse extends Record<string, unknown> {
  /**
   * The ID of the newly created person.
   */
  id: string;
  /**
   * True if the person was created. If the person wasn't created, it's because they already exist. This is based on the email address.
   */
  create: boolean;
}

export interface IFetchPersonFieldValue extends Record<string, unknown> {
  occurrence: number;
  value: string;
}

export class Person extends DisplayModel<IPerson> {
  public readonly className = 'Person';

  public get constraints(): TConstraints<IPerson> {
    return {
      email: {
        email: {
          message: '^Email address must be valid.',
        },
      },
      phone: {
        phone: {
          message: '^Phone number must be valid.',
        },
      },
      emails: {
        list: {
          email: {
            message: '^Email address must be valid.',
          },
        },
        duplicates: {
          caseSensitive: true,
          maximum: 0,
          message: '^Must provide unique email addresses.',
        },
      },
    };
  }

  constructor(attributes: Partial<IPerson> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/data/people/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || this.get('placeholder', {}).name || 'Unknown Person';
  }

  @computed
  public get reachable(): boolean {
    return Boolean(this.attributes.ttd_ids);
  }

  public async getCompanies(query?: string): Promise<ICompanyResponseObject[]> {
    this.assertCollection(this.collection);

    const response = await wretch<IFetchPersonFieldValue[]>(
      this.collection.url('companies', encodeURIComponent(query ?? '')),
      {
        headers: this.collection.getHeaders(),
        method: 'GET',
      },
    );

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  /**
   * Sanitizes the response (overriding unexpected values) - ideally this would be handled in the backend
   */
  public postFetch(response): any {
    const attributesToCastEmptyToUndefined = ['email', 'phone'];
    return attributesToCastEmptyToUndefined.reduce((updatedResponse, attr) => {
      if (updatedResponse[attr] === '') {
        return { ...updatedResponse, [attr]: undefined };
      }
      return updatedResponse;
    }, response);
  }

  public async createPerson(): Promise<ICreatePersonResponse> {
    this.assertCollection(this.collection);
    const createPersonUrl = `${this.collection?.url()}create/`;

    const response = await wretch<ICreatePersonResponse>(createPersonUrl, {
      body: JSON.stringify(this.attributes),
      headers: this.collection.getHeaders(),
      method: 'POST',
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}

export interface IPersonsListParams extends IListParams<IPerson> {
  predicates: Array<IPredicate | IPredicateGroup>;
  mode: 'match_all' | 'match_any';
  lookback_mode: 'unbounded';
  lookback_value?: number;
  exclude: ['breadcrumbs'];
}

export class Persons extends Collection<Person> {
  public getModel(attributes: Partial<IPerson>): Person {
    return new Person(attributes);
  }

  public getClassName(): string {
    return 'persons';
  }

  /**
   * @param listId Use the useId() hook to generate a unique listID.
   */
  public list(
    params: Partial<IPersonsListParams>,
    options: Partial<IListOptions> = {},
    listId?: string,
  ): ListResponse<Person> {
    return super.list(params, options, listId);
  }

  public url(): string;

  /**
   * @param value (required) query
   */
  public url(variant: 'companies', value: string): string;

  public url(variant: 'page'): string;

  public url(variant?: 'companies' | 'page', value?: string | string[]): string {
    if (
      variant &&
      ['companies'].includes(variant) &&
      (Array.isArray(value) ? !value.length : value === undefined)
    ) {
      throw new Error(`Value is required when variant is "${variant}".`);
    }

    switch (variant) {
      case 'companies':
        return `${this.getHostname()}persons/companies/values/?q=${value}`;

      // TODO: Why is the root persons/ endpoint not used for list?
      case 'page':
        return `${this.getHostname()}persons/page/`;

      default:
        return super.url();
    }
  }
}
