import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { when } from 'mobx';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignState, DripCampaign } from '@feathr/blackbox';
import { Button, ButtonValid, Icon } from '@feathr/components';
import { useCampaign } from '@feathr/extender/hooks';
import { StoresContext, useActionBar } from '@feathr/extender/state';
import { useDeepCompareEffect } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import SaveCampaignButton from '../SaveCampaignButton';

export interface IUseActionsEffectProps {
  campaign: DripCampaign;
  childModels: Model[];
  editingStep: number | null;
  getStepErrors: (step: number) => string[];
  grandchildModels: Model[];
  publishErrors: string[];
  isPublishModalOpen: boolean;
  togglePublishModalOpen: () => void;
  // Returned by useWizardState
  currentStep: number;
  lastStep: number;
  onNext: () => void;
  onPrev: () => void;
}

function useActionsEffect({
  campaign,
  childModels,
  currentStep,
  editingStep,
  getStepErrors,
  grandchildModels,
  isPublishModalOpen,
  lastStep,
  onNext,
  onPrev,
  publishErrors,
  togglePublishModalOpen,
}: Readonly<IUseActionsEffectProps>): void {
  const { Segments } = useContext(StoresContext);
  const { setLeftActions, setRightActions } = useActionBar();
  const { disabled, text } = useCampaign({ campaign });
  const { t } = useTranslation();

  useDeepCompareEffect(() => {
    const currentStepHasErrors = !!getStepErrors(currentStep)?.length;
    setLeftActions(
      <>
        <Button disabled={currentStep === 0} onClick={onPrev} prefix={<Icon icon={faArrowLeft} />}>
          {t('Previous')}
        </Button>
        <ButtonValid
          disabled={currentStep === lastStep || currentStepHasErrors || editingStep !== null}
          errors={getStepErrors(currentStep)}
          name={'next_step'}
          onClick={onNext}
          suffix={<Icon icon={faArrowRight} />}
          /*
           * Only give the next button a primary type if the campaign is in draft because other
           * actions become primary in other states.
           */
          type={campaign.get('state') === CampaignState.Draft ? 'primary' : 'secondary'}
        >
          {t('Next')}
        </ButtonValid>
      </>,
    );

    async function preSave(newCampaign: Campaign): Promise<void> {
      // TODO: await BuilderStep.onSync() Feathr/quail#606

      if (newCampaign instanceof DripCampaign) {
        const campaignSegments = newCampaign.get('segments');
        await Promise.all(
          campaignSegments.map(async (s) => {
            if (!s.id) {
              return;
            }
            const campaignSegment = Segments.get(s.id);
            await when(() => !campaignSegment.isPending);

            if (campaignSegment.isEphemeral) {
              await Segments.add(campaignSegment);
            }
          }),
        );
      }
    }

    const isSaveDisabled = disabled.edit && currentStep !== 2;
    // TODO: Fix warning: Cannot update a component (`SaveCampaignButton`) while rendering a different component (`DripCampaignEdit`).
    setRightActions(
      <>
        <SaveCampaignButton
          campaign={campaign}
          childModels={childModels}
          disabled={isSaveDisabled}
          grandchildModels={grandchildModels}
          key={'save'}
          name={'save_changes'}
          preSave={preSave}
          showIcon={true}
          tooltip={isSaveDisabled ? text.disableEdit : undefined}
          type={isSaveDisabled ? 'primary' : 'secondary'}
        />
        {(!disabled.edit || campaign.get('state') === CampaignState.Draft) && (
          <ButtonValid
            disabled={isPublishModalOpen || undefined}
            errors={publishErrors}
            name={'publish'}
            onClick={togglePublishModalOpen}
            prefix={<Icon icon={faCheck} />}
            tooltipPosition={'top-end'}
            type={'success'}
          >
            {t('Publish')}
          </ButtonValid>
        )}
      </>,
    );
  }, [
    // Deps that shouldn't change
    campaign,
    childModels,
    // The disabled dep will cause this useEffect to refresh a large number of times, do don't include it
    getStepErrors,
    grandchildModels,
    lastStep,
    onNext,
    onPrev,
    setLeftActions,
    setRightActions,
    Segments,
    text,
    t,
    togglePublishModalOpen,
    // Changes we need to monitor
    currentStep,
    publishErrors,
    editingStep,
  ]);
}

export default useActionsEffect;
