import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripStepCampaign, TBaseUnitOfTime, TUnitOfTime } from '@feathr/blackbox';
import { ELinkedCampaignSetAction } from '@feathr/blackbox';
import { Fieldset, NumberInput, Select, Well } from '@feathr/components';
import { flattenError } from '@feathr/hooks';

import type { ICustomSelectOption } from '../TargetingOption';
import TargetingOption from '../TargetingOption';

import * as styles from './StepTargeting.css';

interface IStepTargetingProps {
  campaign: DripStepCampaign;
  validationErrors: any;
}

interface IMenuOption {
  id: TUnitOfTime;
  value: string;
}

function StepTargeting({ campaign, validationErrors }: Readonly<IStepTargetingProps>): JSX.Element {
  const { t } = useTranslation();
  const [targeting, setTargeting] = useState(campaign.get('set_action'));
  const [delayUnit, setDelayUnit] = useState<TBaseUnitOfTime>(
    campaign.get('delay_unit') ?? 'minutes',
  );

  const targetingOptions: ICustomSelectOption[] = [
    {
      id: ELinkedCampaignSetAction.previous_email_delivered,
      name: t('Send to everyone'),
      description: t('This email will be sent to everybody that received the previous email.'),
    },
    {
      id: ELinkedCampaignSetAction.not_opened_previous_email,
      name: t('Not opened the previous email'),
      description: t(
        'This email will only be sent to people that received but did not open the previous email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.opened_previous_email,
      name: t('Opened the previous email'),
      description: t('This email will only be sent to people that have opened the previous email.'),
    },
    {
      id: ELinkedCampaignSetAction.not_clicked_link_previous_email,
      name: t('Not clicked the previous email'),
      description: t(
        'This email will only be sent to people that received but did not click a link in the previous email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.clicked_link_previous_email,
      name: t('Clicked the previous email'),
      description: t(
        'This email will only be sent to people that have clicked a link in the previous email.',
      ),
    },
  ];

  const delayTextMap = [
    {
      id: ELinkedCampaignSetAction.previous_email_delivered,
      label: t('Delay from delivery'),
      helpText: t(
        'Set the amount of time that should pass between delivering the previous email and sending this email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.not_opened_previous_email,
      label: t('Delay from delivery'),
      helpText: t(
        'Set the amount of time that should pass between delivering the previous email and sending this email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.opened_previous_email,
      label: t('Delay from open'),
      helpText: t(
        'Set the amount of time that should pass between opening the previous email and sending this email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.not_clicked_link_previous_email,
      label: t('Delay from delivery'),
      helpText: t(
        'Set the amount of time that should pass between delivering the previous email and sending this email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.clicked_link_previous_email,
      label: t('Delay from click'),
      helpText: t(
        'Set the amount of time that should pass between clicking a link in the previous email and sending this email.',
      ),
    },
  ];

  const timeOptions: IMenuOption[] = [
    { id: 'minutes', value: 'minutes' },
    { id: 'hours', value: 'hours' },
    { id: 'days', value: 'days' },
    { id: 'weeks', value: 'weeks' },
  ];

  const minimumTimeValues = { minutes: 5, hours: 1, days: 1, weeks: 1 };

  function handleTargetingSelect(option: ICustomSelectOption): void {
    setTargeting(option.id);
    campaign.set({ set_action: option.id });
  }

  function getOptionLabelDelay(option: IMenuOption): string | undefined {
    const delayValue = campaign.get('delay_value');
    return {
      minutes: t('minute', { count: delayValue }),
      hours: t('hour', { count: delayValue }),
      days: t('day', { count: delayValue }),
      weeks: t('week', { count: delayValue }),
    }[option.id];
  }

  function getOptionValue(option: IMenuOption): string {
    return option.value;
  }

  function onSelectSingle(option: IMenuOption): void {
    setDelayUnit(option.id);
    campaign.set({ delay_unit: option.id, delay_value: minimumTimeValues[option.id] });
  }

  const selectedOption = targetingOptions.find((option) => option.id === targeting);
  const selectedDelay = delayTextMap.find((option) => option.id === targeting);

  return (
    <Well layout={'vertical'} theme={'white'}>
      <Fieldset>
        <Select
          additionalContent={!!selectedOption && <p>{selectedOption.description}</p>}
          components={{ Option: TargetingOption }}
          helpText={t('Select who should be sent the email in this step.')}
          label={t('Step targeting')}
          onSelectSingle={handleTargetingSelect}
          options={targetingOptions}
          placeholder={t('Select one')}
          value={selectedOption}
        />
        {selectedDelay && (
          <NumberInput
            attribute={'delay_value'}
            className={styles.delay}
            clearableClassName={styles.delayElement}
            disabled={false}
            helpText={selectedDelay.helpText}
            label={selectedDelay.label}
            min={minimumTimeValues[delayUnit]}
            model={campaign}
            name={'delay_value'}
            suffix={
              <Select
                disabled={false}
                getOptionLabel={getOptionLabelDelay}
                getOptionValue={getOptionValue}
                name={'delay_unit'}
                onSelectSingle={onSelectSingle}
                options={timeOptions}
                value={timeOptions.find((option) => option.id === delayUnit)}
              />
            }
            validationError={flattenError(validationErrors.delay_value)}
          />
        )}
      </Fieldset>
    </Well>
  );
}

export default observer(StepTargeting);
