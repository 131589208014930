import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IWizardProps } from '@feathr/components';
import { Step, Steps } from '@feathr/components';

interface IWizardStepsProps {
  className?: IWizardProps['className'];
  completed: number;
  current: number;
  layout?: IWizardProps['layout'];
  onChange: (step: number) => void;
}

function WizardSteps({
  className,
  completed,
  current,
  layout,
  onChange,
}: Readonly<IWizardStepsProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Steps
      className={className}
      completed={completed}
      current={current}
      layout={layout}
      onChange={onChange}
    >
      <Step key={1} stepIndex={0} title={t('Builder')} />
      <Step key={2} stepIndex={1} title={t('Exclusions')} />
      <Step key={3} stepIndex={2} title={t('Goals')} />
      <Step key={4} stepIndex={3} title={t('Details')} />
    </Steps>
  );
}

export default WizardSteps;
