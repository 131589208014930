import type { JSX } from 'react';
import React from 'react';

import { ActionBar } from '@feathr/components';
import { useActionBar } from '@feathr/extender/state';

function PageActionBar(): JSX.Element | null {
  const { leftActions, rightActions } = useActionBar();

  if (!leftActions && !rightActions) {
    return null;
  }

  return <ActionBar left={leftActions} right={rightActions} />;
}

export default PageActionBar;
