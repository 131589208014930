import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { EmailBaseCampaign } from '@feathr/blackbox';
import { DripCampaign } from '@feathr/blackbox';
import { DatePicker, Well } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { flattenError, moment, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './EmailCampaignSendWindow.css';

interface IEmailCampaignSendWindowProps {
  campaign: EmailBaseCampaign;
  timeToSend?: string;
  isTimeSubtype?: boolean;
  isDisabled?: boolean;
  validationErrors?: TValidateGrouped;
}

function EmailCampaignSendWindow({
  campaign,
  timeToSend,
  isTimeSubtype = false,
  isDisabled = false,
  validationErrors,
}: Readonly<IEmailCampaignSendWindowProps>): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();

  const userTimezone = user.get('timezone');
  const sendStartTimestamp = campaign.get('date_send_start');
  const sendEndTimestamp = campaign.get('date_send_end');

  const sendStartMoment = moment.utc(sendStartTimestamp).local();
  const sendEndMoment = moment.utc(sendEndTimestamp).local();
  const isoSendStartTimestamp =
    sendStartTimestamp && moment.utc(sendStartTimestamp).format(TimeFormat.isoDateTime);
  const isoSendEndTimestamp =
    sendEndTimestamp && moment.utc(sendEndTimestamp).format(TimeFormat.isoDateTime);

  const minStartTimeMoment = moment.utc(sendStartTimestamp).startOf('day').isAfter(moment())
    ? moment().startOf('day')
    : moment();
  const minEndTimeMoment = moment.utc(sendEndTimestamp).startOf('day').isAfter(moment())
    ? moment().startOf('day')
    : moment();

  const localTimeToSend = moment
    .utc(timeToSend)
    .tz(userTimezone)
    .format(TimeFormat.timeAndTimeZone);

  function onChangeSendStart(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_send_start: newTimestamp,
      date_start: newMoment.clone().startOf('day').format(TimeFormat.isoDateTime),
    });
  }

  function onChangeDateEnd(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_send_end: newTimestamp,
      /*
       * adding 30 days to the date_end is necessary to correctly aggregate stats
       * in certain attribution models
       */
      date_end: newMoment.clone().add(30, 'days').startOf('day').format(TimeFormat.isoDateTime),
    });
  }

  function handleClearDate(type: 'end' | 'start'): () => void {
    return function () {
      campaign.set({ [`date_${type}`]: undefined, [`date_send_${type}`]: undefined });
    };
  }

  const { startLabel, endLabel } = (() => {
    if (campaign instanceof DripCampaign) {
      return {
        startLabel: t('Enrollment start date/time'),
        endLabel: t('Enrollment end date/time'),
      };
    }
    return {
      startLabel: t('Start date/time'),
      endLabel: t('End date/time'),
    };
  })();

  return (
    <>
      {isTimeSubtype && (
        <Well
          description={
            <>
              <p>
                {t(
                  'Make sure that your start date/time is set before the daily email send time and your end date/time is set after it to send emails on those dates.',
                )}
              </p>
              <span className={styles.time}>{localTimeToSend}</span>
            </>
          }
          label={'Daily email send time'}
        />
      )}
      <DatePicker
        autoComplete={'off'}
        dateFormat={'MMM d, yyyy h:mm aa'}
        disabled={isDisabled}
        helpPlacement={'top'}
        helpText={
          isTimeSubtype
            ? t(
                'People who perform the trigger after this time will be sent the email in this campaign.',
              )
            : t(
                'Choose the time that Feathr should start sending emails to contacts who perform the trigger. {{dateTimeUtc}}',
                { dateTimeUtc: sendStartMoment.utc().format(TimeFormat.pickerDateTimeZone) },
              )
        }
        isClearable={true}
        label={startLabel}
        maxTime={sendStartMoment.local().endOf('day').toDate()}
        minDate={new Date()}
        minTime={minStartTimeMoment.toDate()}
        name={'date_send_start'}
        onClear={handleClearDate('start')}
        onDateStrChange={onChangeSendStart}
        showTimeSelect={true}
        timeIntervals={5}
        timezone={userTimezone}
        validationError={flattenError(validationErrors?.date_send_start)}
        value={isoSendStartTimestamp}
      />
      <DatePicker
        autoComplete={'off'}
        dateFormat={'MMM d, yyyy h:mm aa'}
        disabled={isDisabled}
        helpPlacement={'top'}
        helpText={
          isTimeSubtype
            ? t(
                'People who perform the trigger before this time will be sent the email in this campaign.',
              )
            : t(
                'Choose the time that Feathr should stop sending emails to contacts who perform the trigger. {{dateTimeUtc}}',
                { dateTimeUtc: sendEndMoment.utc().format(TimeFormat.pickerDateTimeZone) },
              )
        }
        isClearable={true}
        label={endLabel}
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
        maxTime={sendEndMoment.local().endOf('day').toDate()}
        minDate={new Date()}
        minTime={minEndTimeMoment.toDate()}
        name={'date_send_end'}
        onClear={handleClearDate('end')}
        onDateStrChange={onChangeDateEnd}
        showTimeSelect={true}
        timeIntervals={5}
        timezone={userTimezone}
        validationError={flattenError(validationErrors?.date_send_end)}
        value={isoSendEndTimestamp}
      />
    </>
  );
}

export default observer(EmailCampaignSendWindow);
