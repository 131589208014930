import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Account, TCampaignGroup } from '@feathr/blackbox';
import { CampaignClass, CampaignGroupMap, CampaignLabelMap } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags } from '@feathr/extender/state';

interface IAccessConditions {
  showGoogleAds: boolean;
  showDripCampaigns: boolean;
}

interface ITypeOption {
  id: CampaignClass | 'monetization';
  name?: string;
  access?: (account: Account, conditions: IAccessConditions) => boolean;
}

const typeOptions: ITypeOption[] = [
  {
    id: CampaignClass.Segment,
    name: CampaignLabelMap.get(CampaignClass.Segment),
    access: () => true,
  },
  {
    id: CampaignClass.Facebook,
    name: CampaignLabelMap.get(CampaignClass.Facebook),
    access: () => true,
  },
  {
    id: CampaignClass.EmailListFacebook,
    name: CampaignLabelMap.get(CampaignClass.EmailListFacebook),
    access: (account) => account.isFinchLegacy,
  },
  {
    id: CampaignClass.Search,
    name: CampaignLabelMap.get(CampaignClass.Search),
    access: (account) => account.isFinchLegacy,
  },
  {
    id: CampaignClass.EmailList,
    name: CampaignLabelMap.get(CampaignClass.EmailList),
    access: (account) => account.isFinchLegacy,
  },
  {
    id: CampaignClass.SeedSegment,
    name: CampaignLabelMap.get(CampaignClass.SeedSegment),
    access: (account) => account.isFinchLegacy,
  },
  {
    id: CampaignClass.Affinity,
    name: CampaignLabelMap.get(CampaignClass.Affinity),
    access: (account) => account.isFinchLegacy,
  },
  {
    id: CampaignClass.MobileGeoFencing,
    name: CampaignLabelMap.get(CampaignClass.MobileGeoFencing),
    access: () => true,
  },
  {
    id: CampaignClass.MobileGeoFenceRetargeting,
    name: CampaignLabelMap.get(CampaignClass.MobileGeoFenceRetargeting),
    access: () => true,
  },
  {
    id: CampaignClass.TrackedLink,
    name: CampaignLabelMap.get(CampaignClass.TrackedLink),
    access: () => true,
  },
  {
    id: CampaignClass.LandingPage,
    name: CampaignLabelMap.get(CampaignClass.LandingPage),
    access: () => true,
  },
  {
    id: CampaignClass.Conversation,
    name: CampaignLabelMap.get(CampaignClass.Conversation),
    access: () => true,
  },
  {
    id: CampaignClass.Referral,
    name: CampaignLabelMap.get(CampaignClass.Referral),
    access: (account) => account.isFalcon,
  },
  {
    id: CampaignClass.Drip,
    name: CampaignLabelMap.get(CampaignClass.Drip),
    access: (_, conditions): boolean => conditions.showDripCampaigns,
  },
  {
    id: CampaignClass.PinpointEmail,
    name: CampaignLabelMap.get(CampaignClass.PinpointEmail),
    access: () => true,
  },
  {
    id: CampaignClass.SmartPinpointEmail,
    name: CampaignLabelMap.get(CampaignClass.SmartPinpointEmail),
    access: () => true,
  },
  {
    id: CampaignClass.AutoPinpointEmail,
    name: CampaignLabelMap.get(CampaignClass.AutoPinpointEmail),
    access: () => true,
  },
  {
    id: CampaignClass.GoogleAdsSmart,
    name: CampaignLabelMap.get(CampaignClass.GoogleAdsSmart),
    access: (_, conditions): boolean => conditions.showGoogleAds,
  },
  {
    id: 'monetization',
    name: 'Monetization',
    access: (account) => account.hasMonetization,
  },
];

interface IProps {
  campaignGroup: TCampaignGroup;
  onChange: (value?: Array<CampaignClass | 'monetization'>) => void;
  value?: Array<CampaignClass | 'monetization'>;
}

function CampaignTypeSelect({ campaignGroup, value = [], onChange }: IProps): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const showGoogleAds = useGoogleAdsPermissions();
  const { showDripCampaigns = false } = useFlags();

  const conditions: IAccessConditions = {
    showGoogleAds,
    showDripCampaigns,
  };

  const typeValue: ITypeOption[] = value.reduce((acc, cur) => {
    const option = typeOptions.find(({ id }) => id === cur);
    if (option) {
      acc.push(option);
    }
    return acc;
  }, [] as ITypeOption[]);

  const filteredTypeOptions = typeOptions
    .filter(({ access }) => {
      if (access === undefined) {
        return true;
      }
      return !!account && access(account, conditions);
    })
    .filter(({ id }) => {
      if (id === 'monetization') {
        return campaignGroup === 'all';
      }
      return CampaignGroupMap[campaignGroup].includes(id);
    });

  function handleSelectTypes(types: ITypeOption[] = []): void {
    onChange(types.map(({ id }) => id));
  }

  return (
    <Select<ITypeOption>
      id={'typeSelect'}
      isClearable={true}
      isMulti={true}
      label={t('Type')}
      name={'select-campaign-type'}
      onClear={handleSelectTypes}
      onSelectMulti={handleSelectTypes}
      options={filteredTypeOptions}
      placeholder={t('All campaign types')}
      value={typeValue}
    />
  );
}

export default observer(CampaignTypeSelect);
