import {
  faArrowRotateLeft,
  faArrowsRotate,
  faEye,
  faFloppyDisk,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Template, TemplateClass } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  Chip,
  ContextMenu,
  Drawer,
  FullScreenModal,
  Icon,
  Spinner,
  Time,
  toast,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors, TimeFormat } from '@feathr/hooks';

import TemplatePreview from '../TemplatePreview';
import { TemplateSelectTable } from '../TemplateSelect';
import type { ITemplateSendTestFormErrors } from '../TemplateSendTestForm';
import TemplateSendTestForm from '../TemplateSendTestForm';
import TemplateSelectAndEditModalEditor from './TemplateSelectAndEditModalEditor';

import * as styles from './TemplateSelectAndEditModal.css';

type TDisclosure = readonly [
  boolean,
  {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  },
];

interface IProps {
  disclosure: TDisclosure;
  initialState?: TTemplateSelectAndEditState;
  onChange: (templateId?: string) => Promise<void> | void;
  templateClass: TemplateClass;
  templateId?: string;
}

export type TTemplateSelectAndEditState =
  // Select a template; templateId is undefined, or we are changing template
  | 'selecting'
  // Display loading spinner; async create the template
  | 'loading'
  // Edit template; templateId is set
  | 'editing'
  // Preview email
  | 'previewing'
  // Configure test email in drawer on top of preview
  | 'sending';

/*
 * - While in 'selecting' state, if we select a template, onChange is triggered and we go
 *   to 'editing' state
 */

function TemplateSelectAndEditModal({
  disclosure,
  initialState,
  onChange,
  templateClass,
  templateId,
}: Readonly<IProps>): JSX.Element {
  const [isModalOpen, { close: closeModal }] = disclosure;

  const { Templates } = useContext(StoresContext);
  const { t } = useTranslation();

  const [isDrawerOpen, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);

  function getStateFromTemplateId(templateId?: string): TTemplateSelectAndEditState {
    if (templateId) {
      return 'editing';
    }
    return 'selecting';
  }

  const [state, setState] = useState<TTemplateSelectAndEditState>(
    getStateFromTemplateId(templateId),
  );

  // When templateId changes, update state
  useEffect(() => {
    setState(getStateFromTemplateId(templateId));
  }, [templateId]);

  // When initialState changes, update state
  useEffect(() => {
    if (initialState) {
      setState(initialState);
    }
  }, [initialState]);

  const template = templateId ? Templates.get(templateId) : undefined;

  function handleClose(): void {
    if (isDrawerOpen) {
      closeDrawer();
    } else {
      /*
       * When modal is closed, reset state to initialState, falling back to the initial state based
       * on templateId
       */
      setState(initialState ?? getStateFromTemplateId(templateId));
      closeModal();
    }
  }

  function handleChangeTemplate(): void {
    setState('selecting');
  }

  function handleConfigureTestEmail(): void {
    setState('sending');
    openDrawer();
  }

  function handlePreviewEmail(): void {
    setState('previewing');
  }

  function handleViewEditor(): void {
    setState('editing');
  }

  const validatePreviewEmailRef = useRef<() => ITemplateSendTestFormErrors>();

  function handlePreviewEmailValidate(): string[] {
    if (validatePreviewEmailRef.current) {
      return flattenErrors(validatePreviewEmailRef.current());
    }
    return [];
  }

  const sendPreviewEmailRef = useRef<() => Promise<boolean>>();

  async function handlePreviewEmailSend(): Promise<void> {
    if (sendPreviewEmailRef.current) {
      const success = await sendPreviewEmailRef.current();
      if (success) {
        setState('previewing');
        closeDrawer();
      }
    }
  }

  function isBannerTemplate(template: Template): boolean {
    return template.isBanner && !!template.get('bannersnack_enabled');
  }

  async function handleSave(): Promise<void> {
    if (!template) {
      return;
    }

    const response = await template.patchDirty();

    if (response.isErrored) {
      toast(t('Something went wrong. Please try again.'), { type: ToastType.ERROR });
      return;
    }

    if (!isBannerTemplate(template)) {
      toast(
        t('Changes saved. Domains linked in this email were added to your domain allow list.'),
        {
          type: ToastType.SUCCESS,
        },
      );
    } else {
      toast(t('Changes saved.'), { type: ToastType.SUCCESS });
    }
  }

  async function handleTemplateChange(templateId?: string): Promise<void> {
    if (onChange) {
      setState('loading');
      await onChange(templateId);
      setState(getStateFromTemplateId(templateId));
    }
  }

  function getTitle(state: TTemplateSelectAndEditState): ReactNode {
    switch (state) {
      case 'editing':
        return (
          <>
            {t('Design your email')}
            <span className={styles.delimiter}>/</span>
            <Chip>{template!.name}</Chip>
          </>
        );

      case 'previewing':
        return (
          <>
            {t('Preview email')}
            <span className={styles.delimiter}>/</span>
            <Chip>{template!.name}</Chip>
          </>
        );

      default:
        return t('Pick your template');
    }
  }

  const description =
    state === 'selecting'
      ? t('From the options below select a template that suits your needs')
      : undefined;

  const editingActions = (
    <>
      <ContextMenu iconName={'ellipsis'} position={'top-end'}>
        <ContextMenu.Item onClick={handleChangeTemplate} prefix={<Icon icon={faArrowsRotate} />}>
          {t('Change template')}
        </ContextMenu.Item>
        <ContextMenu.Item onClick={handleConfigureTestEmail} prefix={<Icon icon={faSliders} />}>
          {t('Test email')}
        </ContextMenu.Item>
        <ContextMenu.Item onClick={handlePreviewEmail} prefix={<Icon icon={faEye} />}>
          {t('Preview email')}
        </ContextMenu.Item>
      </ContextMenu>
      <Button onClick={handleSave} prefix={<Icon icon={faFloppyDisk} />} type={'primary'}>
        {t('Save changes')}
      </Button>
    </>
  );
  const previewActions = (
    <>
      <Button
        onClick={handleConfigureTestEmail}
        prefix={<Icon icon={faSliders} />}
        type={'primary'}
      >
        {t('Test email')}
      </Button>
      <Button
        onClick={handleViewEditor}
        prefix={<Icon icon={faArrowRotateLeft} />}
        type={'primary'}
      >
        {t('Back to editor')}
      </Button>
    </>
  );

  const actions = ((): ReactNode => {
    if (state === 'editing') {
      return editingActions;
    }
    if (state === 'previewing' || state === 'sending') {
      return previewActions;
    }
    return undefined;
  })();

  return (
    <>
      <FullScreenModal
        description={description}
        leftActions={
          state === 'editing' && (
            <span>
              <strong>Last Saved:</strong>{' '}
              <Time
                format={TimeFormat.shortWeekdayDateTime}
                timestamp={template!.get('date_last_modified')}
                wrapperClassName={styles.lastSaved}
              />
            </span>
          )
        }
        onClose={handleClose}
        opened={isModalOpen}
        rightActions={actions}
        theme={state === 'editing' ? 'flush' : 'default'}
        title={getTitle(state)}
        tooltip={t('Back to builder')}
      >
        {state === 'selecting' && (
          <TemplateSelectTable
            label={t('Pick your template')}
            onChange={handleTemplateChange}
            templateClass={templateClass}
            value={templateId}
          />
        )}
        {state === 'loading' && (
          <div>
            <Spinner size={20} /> {t('Creating your email...')}
          </div>
        )}
        {state === 'editing' && <TemplateSelectAndEditModalEditor template={template!} />}
        {(state === 'previewing' || state === 'sending') && (
          <TemplatePreview template={template!} visible={true} />
        )}
      </FullScreenModal>
      <Drawer
        isOpen={isDrawerOpen}
        name={'drawer-test-email'}
        onClose={closeDrawer}
        title={t('Test Email')}
      >
        <Drawer.Content addVerticalGap={true}>
          <TemplateSendTestForm template={template!}>
            {(form, validate, send): JSX.Element => {
              sendPreviewEmailRef.current = send;
              validatePreviewEmailRef.current = validate;
              return form;
            }}
          </TemplateSendTestForm>
        </Drawer.Content>
        <Drawer.Actions className={styles.drawerActions}>
          <Button onClick={closeDrawer}>{t('Cancel')}</Button>
          <ButtonValid
            // TODO: Fix send button not re-rendering when form is updated
            errors={handlePreviewEmailValidate()}
            onClick={handlePreviewEmailSend}
            type={'primary'}
          >
            {t('Send')}
          </ButtonValid>
        </Drawer.Actions>
      </Drawer>
    </>
  );
}

export default observer(TemplateSelectAndEditModal);
