import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IDripCampaignStepSpec, Template } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  CardV2 as Card,
  InlineEdit,
  Layout,
  StepSection,
  toast,
} from '@feathr/components';
import { useCampaign } from '@feathr/extender/hooks';
import { useAccount } from '@feathr/extender/state';

import { save } from '../../SaveCampaignButton';
import { createPlaceholderStep } from '../DripCampaignEdit.utils';
import type {
  IUseDripStepValidationProps,
  IUseDripStepValidationResponse,
} from '../useDripStepValidation';
import EditAutomation from './EditAutomation/EditAutomation';
import EditInitialEnrollment from './EditInitialEnrollment/EditInitialEnrollment';
import PreviewAutomation from './PreviewAutomation';
import PreviewInitialEnrollment from './PreviewInitialEnrollment';

export const MAX_NUM_STEPS = 8;

interface IBuilderStepProps extends IUseDripStepValidationResponse, IUseDripStepValidationProps {
  setEditingStep: (stepIndex: number | null) => void;
  editingStep: number | null;
  setSteps: React.Dispatch<React.SetStateAction<IDripCampaignStepSpec[]>>;
}

function BuilderStep({
  campaign,
  editingStep,
  setEditingStep,
  steps,
  setSteps,
  errors,
  firstInvalidStepIndex,
  invalidSteps,
}: Readonly<IBuilderStepProps>): JSX.Element {
  const stepRefs = useRef<Array<HTMLDivElement | null>>([]);
  const { t } = useTranslation();
  const account = useAccount();
  const { disabled, text } = useCampaign({ campaign });

  /*
   * On initial render, if there are no steps saved on the drip campaign, we must apply and sync two
   * placeholder steps to the campaign that the user must complete to continue. Without doing this,
   * the wizard skips over step 1 because it cannot find any validation errors.
   */
  useEffect(() => {
    if (steps.length === 0) {
      const initialSteps = [createPlaceholderStep(t), createPlaceholderStep(t)];

      async function createSteps(): Promise<void> {
        for (const step of initialSteps) {
          await onApply(step, false);
          await onSync(step, null);
        }
      }

      (async (): Promise<void> => {
        await createSteps();
      })();

      setSteps(initialSteps);
    }
    return () => {
      stepRefs.current = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleAdd(): Promise<void> {
    const newStep = createPlaceholderStep(t);

    setSteps((prevSteps: IDripCampaignStepSpec[]) => {
      return [...prevSteps, newStep];
    });

    await onSync(newStep, null);
  }

  function handleDelete(stepIndex: number): () => void {
    return (): void => {
      setSteps((prevSteps) => {
        const newSteps = prevSteps.filter((_, i) => i !== stepIndex);
        campaign.set({ step_specs: newSteps });
        return newSteps;
      });
      setEditingStep(null);
    };
  }

  function handleEdit(stepIndex: number): () => void {
    return () => {
      setEditingStep(stepIndex);
      scrollToStep(stepIndex)();
    };
  }

  function scrollToStep(stepIndex: number): () => void {
    return () => {
      const currentRef = stepRefs?.current[stepIndex];
      currentRef?.scrollIntoView({
        behavior: 'smooth',
        // Align the element in the center of the viewport
        block: 'center',
      });
    };
  }

  async function onApply(
    updatedStep: IDripCampaignStepSpec,
    showSuccessToast = true,
    isInitialEnrollment = false,
  ): Promise<void> {
    // IMPORTANT: Reset editingStep BEFORE saving
    setEditingStep(null);

    /* If it is initial enrollment step, apply actions to drip campaign */
    if (isInitialEnrollment) {
      campaign.set({ actions: updatedStep.actions });
    }

    setSteps((prevSteps) => {
      const newSteps = prevSteps.map((step) => (step.key === updatedStep.key ? updatedStep : step));
      campaign.set({ step_specs: newSteps });
      return newSteps;
    });

    await save({
      campaign,
      childModels: [],
      grandchildModels: [],
      shouldChangeState: false,
      t,
      accountId: account.id,
      showSuccessToast,
      forceValidation: true,
    });

    if (editingStep) {
      scrollToStep(editingStep)();
    }
  }

  async function onSync(
    updatedStep: IDripCampaignStepSpec,
    template: Template | null,
  ): Promise<void> {
    setSteps((prevSteps) => {
      const newSteps = prevSteps.map((step) => (step.key === updatedStep.key ? updatedStep : step));
      campaign.set({ step_specs: newSteps });
      return newSteps;
    });

    if (template?.isDirty) {
      try {
        await template.save();
      } catch (error) {
        const message =
          error instanceof Error ? error.message : t('An error occurred while saving the template');
        toast(message, { type: ToastType.ERROR });
      }
    }
  }

  function onCancel(stepIndex: number): () => void {
    return () => {
      setEditingStep(null);
      scrollToStep(stepIndex)();
    };
  }

  function renderStep(stepIndex: number): JSX.Element {
    const cachedCampaign = { ...campaign };
    if (stepIndex === 0) {
      if (editingStep === stepIndex) {
        async function handleApply(updatedStep: IDripCampaignStepSpec): Promise<void> {
          return onApply(updatedStep, true, true);
        }

        return (
          <EditInitialEnrollment
            dripCampaignId={cachedCampaign.id}
            onApply={handleApply}
            onCancel={onCancel(stepIndex)}
            onSync={onSync}
            step={steps[stepIndex]}
          />
        );
      } else {
        return (
          <Card theme={'disabled'} width={'full'}>
            <PreviewInitialEnrollment dripCampaignId={cachedCampaign.id} step={steps[stepIndex]} />
            <Card.Actions>
              <Button
                disabled={disabled.edit || editingStep !== null}
                onClick={handleEdit(stepIndex)}
                tooltip={text.disableEdit}
              >
                {t('Edit step')}
              </Button>
            </Card.Actions>
          </Card>
        );
      }
    } else {
      if (editingStep === stepIndex) {
        return (
          <EditAutomation
            dripCampaignId={cachedCampaign.id}
            onApply={onApply}
            onCancel={onCancel(stepIndex)}
            onSync={onSync}
            step={steps[stepIndex]}
          />
        );
      } else {
        return (
          <Card theme={'disabled'} width={'full'}>
            <PreviewAutomation dripCampaignId={cachedCampaign.id} step={steps[stepIndex]} />
            <Card.Actions>
              <Button
                disabled={disabled.edit || editingStep !== null}
                onClick={handleEdit(stepIndex)}
                tooltip={text.disableEdit}
              >
                {t('Edit step')}
              </Button>
            </Card.Actions>
          </Card>
        );
      }
    }
  }

  function handleChangeStepName(stepIndex: number) {
    return (value?: string): void => {
      setSteps((prevSteps) => {
        const newSteps = prevSteps.map((s, i) => {
          if (i === stepIndex) {
            const newStep = { ...s, name: value ?? '' };
            return newStep;
          }
          return s;
        });
        // Sync changes back to step specs so we can enable the save as draft button
        campaign.set({ step_specs: newSteps });
        return newSteps;
      });
    };
  }

  function handleStepRef(stepIndex: number): (el: HTMLDivElement | null) => void {
    return (el: HTMLDivElement | null): void => {
      stepRefs.current[stepIndex] = el;
    };
  }

  // Disable adding new steps if there are errors or if a step is being edited
  const addStepButtonEditingErrors =
    editingStep !== null ? [t("New steps can't be added while editing")] : [...errors];

  // Override errors if user has reached max number of steps
  const addStepButtonErrors =
    steps.length === MAX_NUM_STEPS
      ? [t('A maximum of {{ numSteps }} steps can be added', { numSteps: MAX_NUM_STEPS })]
      : addStepButtonEditingErrors;

  return (
    <Layout>
      <Card width={'full'}>
        <Card.Header
          description={t(
            'Design the steps of your drip campaign. Each step has an email and automation rules that determine when and how that email should be sent.',
          )}
          title={t('Steps')}
        />
        {/* Do not use addVerticalGap={true} or the step lines will be too short */}
        <Card.Content>
          {steps.map((step, stepIndex) => {
            const isInvalid = invalidSteps.includes(stepIndex);

            return (
              <StepSection
                defaultOpen={stepIndex === firstInvalidStepIndex}
                disabled={disabled.edit}
                isComplete={!isInvalid && stepIndex !== editingStep}
                key={step.key}
                // Skip onDelete for the first 2 steps
                onDelete={stepIndex > 1 ? handleDelete(stepIndex) : undefined}
                stepIndex={stepIndex}
                stepNumber={stepIndex + 1}
              >
                <StepSection.Header>
                  {/* This ref will help us scroll to the top of the step upon completion */}
                  <div ref={handleStepRef(stepIndex)}>
                    <InlineEdit
                      disabled={disabled.edit}
                      onChange={handleChangeStepName(stepIndex)}
                      validationError={step.name === '' ? t("Step name can't be blank") : undefined}
                      value={step.name}
                    />
                  </div>
                </StepSection.Header>
                <StepSection.Content>{renderStep(stepIndex)}</StepSection.Content>
              </StepSection>
            );
          })}
        </Card.Content>
        <Card.Actions>
          <ButtonValid
            disabled={disabled.edit || steps.length === MAX_NUM_STEPS}
            errors={addStepButtonErrors}
            onClick={handleAdd}
            tooltip={text.disableEdit}
            tooltipPosition={'top'}
            type={'secondary'}
          >
            {t('Add step')}
          </ButtonValid>
        </Card.Actions>
      </Card>
    </Layout>
  );
}

export default observer(BuilderStep);
