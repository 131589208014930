import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign, IDripCampaignStepSpec } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import { useWizardState, Wizard } from '@feathr/components';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import { getGoalSegments, validateStepGoals } from '../StepGoals';
import Actions from './Actions';
import BuilderStep from './BuilderStep';
import DetailsStep from './DetailsStep';
import { validate, validateDetails, validateGroupExclusions } from './DripCampaignEdit.utils';
import ExclusionsStep from './ExclusionsStep';
import GoalsStep from './GoalsStep';
import useDripStepValidation from './useDripStepValidation';
import WizardSteps from './WizardSteps';

import * as styles from './DripCampaignEdit.css';

interface IDripCampaignEditProps {
  campaign: DripCampaign;
}

function DripCampaignEdit({ campaign }: Readonly<IDripCampaignEditProps>): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();

  const { Goals, Segments } = useContext(StoresContext);
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const goalSegments = getGoalSegments(goals.models, Segments);
  const groupExclusions = campaign.get('segments', []);

  const [stepSpecs, setStepSpecs] = useState<IDripCampaignStepSpec[]>(
    campaign.get('step_specs', []),
  );

  const {
    errors: stepOneErrors,
    firstInvalidStepIndex,
    invalidSteps,
  } = useDripStepValidation({
    steps: stepSpecs,
    campaign,
  });

  // Set the editing step to the first invalid step index if it exists
  const [editingStep, setEditingStep] = useState<number | null>(firstInvalidStepIndex);

  const stepTwoErrors = flattenErrors(validateGroupExclusions(groupExclusions));
  const stepThreeErrors = flattenErrors(validateStepGoals(goals.models, goalSegments));
  const stepFourErrors = flattenErrors(validateDetails(campaign));

  const getStepErrors = useCallback(
    (step: number): string[] => {
      // If we're loading the wizard, return no errors
      if (step === -1) {
        return [];
      }

      /*
       * Add an extra error to step 1 if the user is editing a step.
       * Sometimes a step can be valid but the user is still editing it.
       */
      if (editingStep !== null && !stepOneErrors.includes(t('Apply changes to continue'))) {
        stepOneErrors.unshift(t('Apply changes to continue'));
      }

      const map = {
        0: stepOneErrors,
        1: stepTwoErrors,
        2: stepThreeErrors,
        3: stepFourErrors,
      };
      return map[step];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editingStep,
      invalidSteps.length,
      stepTwoErrors.length,
      stepThreeErrors.length,
      stepFourErrors.length,
    ],
  );

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    return {
      /*
       * Prevent the user from moving to the next step if there are no steps yet to validate.
       * This gives the builder the chance to create placeholder steps to work with.
       */
      0: !getStepErrors(0).length && stepSpecs.length > 0,
      1: !getStepErrors(1).length,
      2: !getStepErrors(2).length,
      3: !getStepErrors(3).length,
    };
  };

  const segments = campaign
    .get('segments', [])
    .filter(({ id }) => !!id)
    .map(({ id }) => Segments.get(id));

  const childModels: Model[] = [...segments, ...goals.models.slice()];
  const grandchildModels: Model[] = [...goalSegments];

  // Allows the wizard to know when it is ready to move to the next step
  const waitFor: () => boolean = () => {
    // Should wait for necessary models to be loaded - just a shell for now
    return !(campaign.isPending || goals.isPending);
  };

  const { currentStep, completeStep, lastStep, onNext, onPrev, onChangeStep } = useWizardState({
    getCompletedStepMap,
    waitFor,
  });

  const steps = (
    <WizardSteps
      completed={campaign.get('state') === CampaignState.Published ? lastStep : completeStep}
      current={currentStep}
      onChange={onChangeStep}
    />
  );

  const publishErrors = flattenErrors(
    validate({
      account,
      stepOneErrors,
      campaign,
      goals: goals.models,
      goalSegments,
      groupExclusions,
      t,
    }),
  );

  return (
    <Wizard className={styles.wizard} isFullWidth={true} layout={'horizontal'} steps={steps}>
      <section className={styles.main}>
        {currentStep === 0 && (
          <BuilderStep
            campaign={campaign}
            editingStep={editingStep}
            errors={getStepErrors(currentStep)}
            firstInvalidStepIndex={firstInvalidStepIndex}
            invalidSteps={invalidSteps}
            setEditingStep={setEditingStep}
            setSteps={setStepSpecs}
            steps={stepSpecs}
          />
        )}
        {currentStep === 1 && <ExclusionsStep campaign={campaign} />}
        {currentStep === 2 && <GoalsStep campaign={campaign} goals={goals.models} />}
        {currentStep === 3 && <DetailsStep campaign={campaign} />}
      </section>
      <Actions
        campaign={campaign}
        childModels={childModels}
        currentStep={currentStep}
        editingStep={editingStep}
        getStepErrors={getStepErrors}
        grandchildModels={grandchildModels}
        lastStep={lastStep}
        onNext={onNext}
        onPrev={onPrev}
        publishErrors={publishErrors}
      />
    </Wizard>
  );
}

export default observer(DripCampaignEdit);
