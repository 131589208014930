import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ISegment as IGroup, Segment as Group, TLookbackMode } from '@feathr/blackbox';
import type { ISelectOption, ITogglerBaseProps } from '@feathr/components';
import { DatePicker, NumberInput, Select } from '@feathr/components';
import { moment, TimeFormat } from '@feathr/hooks';

import styles from './LookbackMode.css';

interface ILookbackModeOption extends ISelectOption {
  id: TLookbackMode;
}

interface IProps {
  disabled?: boolean;
  group: Group;
}

type TLookbackUnit = 'days' | 'hours' | 'minutes';

function LookbackMode({ disabled = false, group }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const lookbackModeOptions: ITogglerBaseProps<IGroup['lookback_mode']>['options'] = [
    { id: 'unbounded', name: t('from any time') },
    { id: 'absolute', name: t('starting from') },
    { id: 'relative', name: t('within the last') },
    { id: 'before', name: t('before') },
  ];
  const unitsToFactorMap = new Map<TLookbackUnit, number>([
    ['days', 86400000],
    ['hours', 3600000],
    ['minutes', 60000],
  ]);
  const lbValue = group.get('lookback_value');
  const lbUnits = group.get('lookback_units');
  const lbMode = group.get('lookback_mode');

  function handleLookbackValueChange(newValue?: number): void {
    if (lbUnits) {
      group.set({
        lookback_value: (newValue ?? 0) * unitsToFactorMap.get(lbUnits)!,
      });
    }
  }

  function getLookbackValue(id: TLookbackMode): string | number | undefined {
    if (id === 'relative') {
      return 30 * 60 * 1000;
    }
    if (id === 'unbounded') {
      return undefined;
    }
    return moment.utc().format('YYYY-MM-DDTHH:mm:ss');
  }

  function handleOnChange({ id }: ILookbackModeOption): void {
    group.set({
      lookback_mode: id,
      lookback_value: getLookbackValue(id),
      lookback_units: id === 'relative' ? 'minutes' : undefined,
    });
  }

  function handleDateStrChange(date?: string): void {
    if (date) {
      group.set({ lookback_value: date });
    }
  }

  function handleOnSelectSingle(value: { id: TLookbackUnit; name: TLookbackUnit }): void {
    group.set({
      lookback_units: value.id,
      lookback_value: unitsToFactorMap.get(value.id),
    });
  }

  const value = lookbackModeOptions.find(({ id }) => id === lbMode) ?? lookbackModeOptions[0];

  const disabledMode = (
    <span className={styles.disabled}>{t('{{mode}}', { mode: value.name })}</span>
  );

  const parsedLb = (function () {
    if (lbValue === 60000) {
      return 1;
    }
    if (lbValue === 3600000) {
      return 1;
    }
    if (lbValue === 86400000) {
      return 1;
    }

    return lbValue;
  })();

  const options: Array<{ id: TLookbackUnit; name: TLookbackUnit }> = [
    {
      id: 'minutes',
      name: t('minute', { count: +parsedLb }),
    },
    {
      id: 'hours',
      name: t('hour', { count: +parsedLb }),
    },
    {
      id: 'days',
      name: t('day', { count: +parsedLb }),
    },
  ];

  return (
    <>
      {disabled ? (
        disabledMode
      ) : (
        <Select
          className={styles.lookback}
          onChange={handleOnChange}
          options={lookbackModeOptions}
          value={value}
        />
      )}
      {(lbMode === 'absolute' || lbMode === 'before') &&
        (!disabled ? (
          <DatePicker
            onDateStrChange={handleDateStrChange}
            placeholder={t('missing value')}
            selected={lbValue ? new Date(lbValue) : null}
          />
        ) : (
          <span className={styles.disabled}>
            {' ' + moment.utc(lbValue).format(TimeFormat.longDate)}
          </span>
        ))}
      {lbMode === 'relative' &&
        (!disabled ? (
          <>
            <NumberInput
              onChange={handleLookbackValueChange}
              value={lbValue && lbUnits ? +lbValue / unitsToFactorMap.get(lbUnits)! : undefined}
              wrapperClassName={styles.number}
            />
            <Select
              onSelectSingle={handleOnSelectSingle}
              options={options}
              styles={{ container: (provided) => ({ ...provided, width: '120px' }) }}
              value={options.find(({ id }) => id === lbUnits)}
            />
          </>
        ) : (
          <span className={styles.disabled}>
            {` ${+lbValue / (unitsToFactorMap.get(lbUnits || 'minutes') ?? 1)} ${lbUnits}`}
          </span>
        ))}
    </>
  );
}

export default observer(LookbackMode);
