import type { JSX } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IMergeField, PinpointEmailBaseCampaign, Template } from '@feathr/blackbox';
import { CampaignClass, TemplateClass } from '@feathr/blackbox';
import { DebouncedInput, Fieldset, Input } from '@feathr/components';
import MergetagSelect from '@feathr/extender/components/MergetagSelect';
import { StoresContext } from '@feathr/extender/state';
import { flattenError } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';

interface IEmailInformationInputsProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  template?: Template;
  validationErrors: IValidateGrouped;
}

function EmailInformationInputs({
  campaign,
  disabled,
  template,
  validationErrors,
}: Readonly<IEmailInformationInputsProps>): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useContext(StoresContext);
  const [mergetagTemplate] = useState(
    Templates.create({ _cls: TemplateClass.PinpointEmail, account: campaign.get('account') }),
  );
  const [hadFocusSubject, setHadFocusSubject] = useState(false);

  const isPartnerMessageCampaign = campaign.get('_cls') === CampaignClass.PinpointPartnerMessage;

  const handleSelectOption = useCallback(
    (option: IMergeField) => {
      const subject = campaign.get('subject', '');
      campaign.set({ subject: subject.concat(option.value) });
    },
    [campaign],
  );

  function handleDebouncedSubjectChange(newValue?: string): void {
    campaign.set({ subject: newValue ?? '' });
  }

  function handleBlurSubject(): void {
    setHadFocusSubject(true);
  }

  return (
    <Fieldset>
      <DebouncedInput<string>
        defaultValue={campaign.get('subject')}
        onChange={handleDebouncedSubjectChange}
      >
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            disabled={disabled}
            helpText={t('The subject line for the email your audience will receive.')}
            label={t('Subject')}
            name={'email_subject'}
            onBlur={handleBlurSubject}
            onChange={onChangeLiveValue}
            type={'text'}
            validationError={hadFocusSubject ? flattenError(validationErrors?.subject) : undefined}
            value={liveValue}
          />
        )}
      </DebouncedInput>

      <MergetagSelect
        disabled={disabled}
        helpText={t(
          'You can use merge tags to personalize the subject line of your message. Choose the data you want to merge in from this dropdown to insert it at the end of your current subject line.',
        )}
        isPartnerMessageCampaign={isPartnerMessageCampaign}
        name={'merge_tags'}
        onChange={handleSelectOption}
        template={mergetagTemplate}
      />
      {template && (
        <Input
          attribute={'preview_text'}
          disabled={disabled}
          helpText={t(
            'The preview text that displays along with the subject line in some email clients.',
          )}
          label={t('Preview text')}
          model={template}
          optional={true}
          type={'text'}
        />
      )}
    </Fieldset>
  );
}

export default EmailInformationInputs;
