import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import type { IStats } from '@feathr/blackbox';
import { EUsageMetrics } from '@feathr/blackbox';
import type { IPageHeaderProps } from '@feathr/components';
import { isSuperpixelActive, Tab } from '@feathr/components';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import { useAccount, useLocalUrl, useRole, useUsageAndQuota } from '@feathr/extender/state';

import UsageStat, { usageMap } from './UsageStat';

import * as styles from './DataPage.css';

interface IGroupPermissions {
  canClone: boolean;
  canEdit: boolean;
  isReadOnly: boolean;
}

export interface IProps extends IPageProps {
  filters?: React.ReactNode;
  /** If the page is part of the groups section of the app (rather than people) */
  isGroupsSection?: boolean;
  hasTabs?: boolean;
  hideUsageSection?: boolean;
  permissions?: IGroupPermissions;
  sectionTitle: string;
  /**
   * Width of legacy main page content
   * @deprecated Child components should handle width
   */
  width?: 'narrow' | 'wide' | 'full';
}

function DataPage({
  description,
  children,
  filters,
  isGroupsSection = false,
  hasTabs = true,
  hideUsageSection = false,
  permissions,
  sectionTitle,
  title,
  ...props
}: IProps): JSX.Element {
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const { hasSegmentsSome, hasSegments } = useRole();

  const { quota, usage, showUsage } = useUsageAndQuota(account.id, [
    EUsageMetrics.IncludedAnonymousPersons,
    EUsageMetrics.IncludedIdentifiedPersons,
    EUsageMetrics.Breadcrumbs,
  ]);

  // Is the superpixel set up?
  const stats = account.get('stats', { num_crumbs: 0 } as IStats);
  if (!account.isPending && !isSuperpixelActive(stats.num_crumbs || 0)) {
    return <Redirect to={localUrl('/data/pixel?nodata=1')} />;
  }

  const tabs: IPageHeaderProps['tabs'] = [];
  if (isGroupsSection) {
    tabs.push(<Tab key={'people'} link={'people'} title={t('People')} />);
    tabs.push(<Tab key={'analytics'} link={'analytics'} title={t('Analytics')} />);

    if ((hasSegmentsSome && permissions?.canEdit) || hasSegments) {
      tabs.push(<Tab key={'settings'} link={'settings'} title={t('Settings')} />);
    }
  }

  return (
    <Page
      {...props}
      loading={account.isPending}
      tabs={hasTabs ? tabs : undefined}
      title={sectionTitle}
    >
      {title && <h3 className={styles.heading}>{title}</h3>}
      {/* Optionally hide the usage section if we don't want the stats rendering. */}
      {!hideUsageSection && showUsage && (
        <div className={styles.usageContainer} key={'usage'}>
          {Object.keys(usage).map(
            (key: string): JSX.Element => (
              <UsageStat
                key={key}
                limit={(quota[key] && numeral(quota[key]).format('0,0')) ?? t('unlimited')}
                t={t}
                title={usageMap(t, key as EUsageMetrics)}
                usage={numeral(usage[key]).format('0,0')}
              />
            ),
          )}
        </div>
      )}
      <>
        {!!filters && <div className={classNames(styles.filters)}>{filters}</div>}
        {!!description && <div className={styles.description}>{description}</div>}
        {children}
      </>
    </Page>
  );
}

export default observer(DataPage);
