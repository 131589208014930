import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IUseCampaignTextReturn {
  disableEmailRepublish: string;
  disableLookalikeClone: string;
  disableStopOrRepublishPastEndDate: string;
  disableSingleSendStopStartEndDate: string;
  disableEdit: string;
}

function useCampaignText(): IUseCampaignTextReturn {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      disableEmailRepublish: t(
        'Auto Send and Smart campaigns cannot be re-enabled and should instead be cloned',
      ),
      disableLookalikeClone: t(
        'This lookalike campaign type is outdated and can no longer be cloned',
      ),
      disableStopOrRepublishPastEndDate: t(
        'This campaign can no longer be published or stopped because it is past the end date',
      ),
      disableSingleSendStopStartEndDate: t(
        'Single Send campaigns cannot be stopped past their start date',
      ),
      disableEdit: t('This campaign can no longer be edited'),
    }),
    [t],
  );
}

export default useCampaignText;
